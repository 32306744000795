@import 'colors';

.qr-codes {
  &-button {
    margin-bottom: 28px;
    width: fit-content;
    margin-left: auto;
  }
  &-header {
    &.ant-layout-header {
      height: 64px !important;
    }
    div {
      padding: 25px 28px 0;
      display: flex;
      height: 100%;
      width: 100%;
      background-color: white;
      h2 {
        margin: 0;
        line-height: normal;
      }
    }
  }
  .qr-codes-tabs {
    .ant-tabs-nav-wrap {
      padding: 0 28px;
    }
    .ant-tabs-content-holder {
      margin: 15px 28px;
    }
    .ant-tabs-tab-btn {
      font-size: 18px;
    }

    .ant-tabs-tab:not(:first-of-type) {
      margin-left: 50px;
    }
  }
}
.qr-codes-summary {
  &-header {
    display: flex;
  }
  .qr-codes-container {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;

    .qr-codes-info-card {
      height: 260px;
      margin: 10px;
      flex: 1;

      .loading-component {
        height: 185px;
      }

      &-display {
        max-height: 145px;
        overflow-y: auto;
      }
      .ant-select {
        width: 100%;
      }
      &-stats {
        margin-top: 30px;
        overflow-y: auto;
        max-height: 160px;
        margin-right: -24px;
        p {
          margin: 0;
        }
      }
    }
  }
  .qr-codes-remaining {
    display: flex;
    flex-direction: column;
    &-title {
      color: $shade-gray-2;
      font-size: 12px;
      margin-bottom: 0;
    }
    &-amount {
      color: $primary-yellow;
      font-size: 50px;
      font-weight: bold;
      margin: 0;
    }
    &-message {
      margin: 0;
    }
    .qr-codes-card-button {
      width: fit-content;
      margin-top: 29px;
    }
  }
}

.qr-bundles {
  background-color: #fff;
  padding: 20px;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      width: fit-content;
      margin-bottom: 28px;
    }
    .ant-radio-group {
      margin-left: auto;
      margin-right: 18px;
    }
  }
  form {
    .ant-form-item-label {
      text-align: left;
    }
  }
}

.purchase-history {
  background-color: white;
  padding: 20px;
  &-header {
    display: flex;
    justify-content: space-between;

    h3 {
      width: fit-content;
    }
    .ant-radio-group {
      margin-left: auto;
      margin-right: 18px;
    }
  }
}

.project-qr-codes {
  p {
    margin-bottom: 0;
  }

  &-container {
    display: flex;
  }

  &-project {
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 2;
    margin-right: 20px;
  }

  &-total {
    flex: 1;
    //padding-left: 40px;
    margin-left: 20px;
  }
}

.generate-codes-form {
  .ant-form-item {
    //height: 100px
  }
}

.across-project-card {
  .ant-card-body {
    max-height: 200px;
    overflow-y: auto;
  }
}

.confirm-download {
  width: 400px;
  margin: 0 auto;
}

.generate-code-message {
  margin-top: 10px;
  display: flex;
  color: $shade-gray-4;

  p {
    margin: 0;
  }

  .anticon {
    margin: 4px 6px 0 0;
  }
}
