@import 'colors';

.account {
  &-header {
    &.ant-layout-header {
      height: 64px !important;
    }
    div {
      padding: 25px 28px 0;
      display: flex;
      height: 100%;
      width: 100%;
      background-color: white;
      h2 {
        margin: 0;
        line-height: normal;
      }
    }
  }
  .personal-details,
  .storage-settings {
    padding: 28px 25px;
    background-color: white;

    form {
      .ant-form-item-label {
        text-align: left;
      }
    }
  }
  .profile-details-tabs {
    .ant-tabs-nav-wrap {
      padding: 0 28px;
    }
    .ant-tabs-content-holder {
      margin: 15px 28px;
    }
    .ant-tabs-tab-btn {
      font-size: 18px;
    }

    .ant-tabs-tab:not(:first-of-type) {
      margin-left: 50px;
    }
  }
  .subscription {
    padding: 25px 28px;
    background-color: white;
    .packages-list {
      margin-top: 20px;
    }
    .subscription-packages {
      border: 1px solid #8c8c8c;
      padding: 35px 30px;
      .package {
        border-right: 1px solid #8c8c8c;
        &:first-child {
          padding: 0 30px 0 0;
        }
        &:nth-child(2),
        &:last-child {
          padding: 0 30px;
        }
        &:nth-child(3) {
          border: 0;
        }
        button.select-package {
          margin-bottom: 30px;
        }
        .price-per-image-header {
          display: flex;
          justify-content: space-between;
          span {
            padding: 5px;
            background-color: #fbba09;
            color: #0a387c;
          }
        }
        .price-per-picture {
          .ant-list-item-meta-content {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
  .storage-settings {
    .allow-user-storage-bucket--label {
      margin-bottom: 30px;
      display: flex;

      cursor: pointer;
      flex-direction: column;
    }
    .select {
      width: 87%;
      @media (max-width: 575px) {
        & {
          width: 100%;
        }
      }
    }
  }
}
.active-account {
  &-header {
    &.ant-layout-header {
      height: 64px !important;
    }
    div {
      padding: 25px 28px 0;
      display: flex;
      height: 100%;
      width: 100%;
      background-color: white;
      h2 {
        margin: 0;
        line-height: normal;
      }
    }
  }
  .personal-details,
  .storage-settings {
    margin: 28px;
    padding: 28px 25px;
    background-color: #fff;
    form {
      .ant-form-item-label {
        text-align: left;
      }
    }
  }
}
.payment-gateway-form {
  padding: 0 37px;
  label {
    background-color: #e4e8ea;
    padding: 0 15px;
    width: 100%;
    &:first-child {
      margin-bottom: 20px;
    }
    &:nth-child(2) {
      margin-left: 0;
    }
  }
}

.account-package-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.account-list {
  margin-right: 0 !important;

  &:hover {
    box-shadow: 10px 15px 20px #00000031;
    cursor: pointer;
  }
  .pl-package-option-container {
    list-style-type: disc;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .pl-package-option {
    font-size: 1rem;
    &::marker {
      content: '✓';
    }
  }
  .pl-package-option,
  .pl-package-body {
    color: $primary-dark-blue;
  }

  .pl-package-header-inner {
    display: flex;
    justify-content: center;
  }

  .pl-pricing-header {
    text-align: center;
  }
  .pl-pricing-strikethrough {
    text-decoration: line-through;
  }

  .select-button {
    margin-left: auto;
    border-radius: 5px;
    background-color: $primary-blue-light;
    color: $primary-blue;
    text-shadow: none;

    &.orange {
      background-color: $primary-yellow-light;
      color: $primary-yellow;
      border-color: $primary-yellow;
    }
  }
}

.selected-package {
  display: flex;
  height: 37px;

  h4 {
    margin: 0 auto 15px;
    width: fit-content;
    position: relative;
    text-align: center;
    color: $primary-yellow;
  }

  h4:before {
    content: ' ';
    display: block;
    height: 2px;
    width: 40px;
    position: absolute;
    background: $primary-yellow;
    top: 50%;
    right: -50%;
  }

  h4:after {
    content: ' ';
    height: 2px;
    width: 40px;
    background: $primary-yellow;
    display: block;
    position: absolute;
    top: 50%;
    left: -50%;
  }
}

.account-change-tab {
  color: #1890ff;
  cursor: pointer;
}

.sub-steps {
  &-actions {
    margin-top: 50px;
    display: flex;

    .ant-btn-primary {
      margin-left: auto;
    }
  }
}

.email-form {
  margin: 30px 0;
  //width: 50%;

  &-buttons {
    margin-top: 30px;
    .ant-btn-primary {
      margin-right: 20px;
    }
  }

  //input {
  //  width: 25%;
  //}
}

.all-subscriptions {
  display: flex;
  margin-bottom: 20px;

  h3 {
    margin: 0;
  }

  .ant-space {
    margin-left: auto;
  }
}

.all-subscriptions-table {
  .ant-table-row {
    cursor: pointer;
  }
}

.view-subscription {
  &-header {
    &.ant-layout-header {
      height: 64px !important;
    }

    .ant-btn {
      margin: auto 15px auto 0;
      &:last-child {
        margin: auto 25px auto 0;
      }
    }

    div {
      display: flex;
      height: 100%;
      width: 100%;
      background-color: white;

      h2 {
        margin: auto auto auto 25px;
        line-height: 10px;
      }
    }
  }
}

.view-subscription-content {
  padding: 25px;
}

.subscription-details {
  .ant-space {
    width: 80%;
  }
}

.account-analytics {
  &-header {
    .ant-col {
      padding: 10px 2px;
      font-weight: bold;
      background-color: $shade-gray-5;

      &:not(:last-of-type) {
        border-right: 1px solid white;
      }
    }
  }

  &-info {
    border-bottom: 1px solid black;
    padding: 15px 0;
  }

  .subscription-total-item {
    margin: 0;
  }
}
