.ant-modal-wrap {
  .ant-modal-content {
    border-radius: 7px;
    .ant-modal-close-x {
      .anticon {
        vertical-align: -0.5em;
      }
    }

    .ant-modal-header {
      padding: 22px 18px;
      border-radius: 7px 7px 0 0;
      border-top: 1px solid $shade-gray;

      .ant-modal-title {
        font-size: 18px;
      }
    }

    .ant-modal-body {
      padding: 22px 10px 6px;
      border-bottom: 1px solid $shade-gray;
      border-radius: 0 0 7px 7px;

      form.ant-form.ant-form-vertical {
        .ant-row.ant-form-item {
          .ant-col.ant-form-item-label {
            ::before {
              content: '';
            }
          }
        }
      }
    }

    .ant-modal-footer {
      padding: 12px 20px;
      border-top: none;

      .ant-btn:not(.ant-btn-primary) {
        border-color: $primary-yellow !important;
        color: $primary-yellow;

        &:hover {
          background-color: rgba($primary-yellow, 0.1);
        }
      }
    }
  }
}

// Styling for the safari message modal
.sm-modal {
  margin: 10px 50px;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    margin: 10px 0 0;
  }

  figcaption {
    font-size: 12px;
    text-align: right;
  }

  .ant-btn {
    width: 100px;
  }

  .ant-space {
    margin: 20px auto 0;
    text-align: center;
  }
}
