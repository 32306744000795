@import 'colors';

.dashboard-container {
  padding: 30px;
}

.dashboard-header {
  margin: -30px -30px 30px;
}

.codes-remaining {
  border-radius: 7px;
  margin-bottom: 27px;

  .ant-card-body {
    display: flex;
  }

  h3 {
    margin: auto 15px auto 0;
    height: fit-content;
    font-size: 16px;
    line-height: 16px;
  }

  h2 {
    height: fit-content;
    margin: auto auto auto 0;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    color: $primary-yellow;
  }
}

.stats-card {
  border-radius: 7px;
  max-width: 555px;
  width: 45%;
  height: 376px;

  &:first-of-type {
    margin-right: 26px;
  }

  &-header {
    display: flex;
  }

  h3 {
    font-size: 16px;
    line-height: 16px;
    margin: auto 0 auto 0;
  }

  .ant-select {
    width: 283px;
    margin-left: auto;
  }

  .doughnut-container {
    margin: auto;
    height: 350px;
    width: 350px;
  }

  .bar-container {
    margin: 20px auto auto;
  }

  .photographer-table {
    margin-top: 20px;

    .ant-table-cell {
      padding: 10px 16px;
    }

    &-name {
      display: flex;

      p {
        margin: auto 0 auto 13px;
        color: $primary-blue;
        font-size: 14px;
        font-weight: 500;
        height: fit-content;
      }

      &-circle {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        border: 1px solid;

        font-weight: bold;
        display: flex;

        div {
          margin: auto;
          height: fit-content;
          line-height: 14px;
        }
      }
    }
  }
}
