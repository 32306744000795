@import 'colors';

.payment-success {
  padding: 16px 28px;

  &-details {
    margin-top: 20px;
    width: 50%;

    img {
      width: 120px;
    }

    .ant-row {
      margin-bottom: 20px;
    }
  }
}

.payment-cancelled {
  margin-bottom: 20px;
}

.stripe-redirect {
  margin: 16px 28px;
  display: flex;
  flex-direction: column;
  width: fit-content;

  .ant-spin {
    margin: 10px auto 0;
  }

  .ant-btn {
    width: 150px;
    margin: 0 auto;
  }
}

.payment-form {
  margin-top: 30px;
  width: 50%;

  .ant-btn-primary {
    margin-top: 30px;
  }
}
