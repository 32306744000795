@import '~antd/dist/antd.css';
@import 'colors.scss';
@import 'modal.scss';
@import 'buttons.scss';
@import 'notification.scss';

// importing individual features styles
@import 'dashboard.scss';
@import 'login.scss';
@import 'projects.scss';
@import 'team.scss';
@import 'templates.scss';
@import 'qr-codes.scss';
@import 'sales.scss';
@import 'register.scss';
@import 'payments.scss';
@import 'account.scss';
@import 'website.scss';
@import 'popup.scss';

// .ant-modal-wrap {
//   display: flex;

//   .ant-modal {
//     margin: auto;
//     top: 0;
//   }
// }

body {
  background-color: #eaeaea !important;
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 15%);
}

.react-datepicker-wrapper {
  width: 100%;
}

.sidebar {
  height: 100%;
  max-width: 246px;
  width: 100%;

  background-color: $primary-blue;

  h2 {
    color: white;
    font-size: 35px;
  }

  .image-menu-option {
    img {
      width: 150px;
    }

    span {
      height: fit-content;
      margin-top: 25px;
    }
  }

  .ant-menu-item {
    display: flex;
  }

  .ant-menu-item:not(:first-of-type) {
    font-size: 20px;
    height: 50px;
    &:hover {
      background-color: rgba($primary-yellow, 0.2);
    }

    span {
      margin: auto 0;
    }

    a {
      color: white;
    }

    .ant-image {
      margin-right: 10px;
    }
  }

  .ant-menu-item-selected {
    background-color: $primary-yellow !important;
  }
}

.navbar {
  border-bottom: 1px solid $shade-silver;
  background-color: white;
  padding-left: 30px;
  height: 48px;
  display: flex;

  .account-selector {
    margin: auto 0;
    line-height: 0;

    .account-selector__select {
      min-width: 150px;
    }
  }

  .user-icon {
    width: 28px;
    height: 28px;
    cursor: pointer;
    margin: auto 34px auto auto;
  }

  .notification-icon {
    width: 20px;
    height: 20px;
    margin: auto 34px auto auto;
  }
}

.app-layout {
  height: 100vh;
  overflow-y: hidden;

  aside.ant-layout-sider {
    // using important to override inline styling
    width: 230px !important;
    min-width: 230px !important;
    flex: 0 0 230px !important;
  }

  .ant-layout-header {
    padding: 0;
    background-color: transparent;
    height: 48px;
  }

  .ant-layout {
    background-color: $shade-blue-1;
  }
}

.empty-list {
  margin-top: 17px;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    width: 36%;
    margin: 0 auto;
    align-items: center;
    text-align: center;

    img {
      width: 150px;
      margin-bottom: 28px;
    }

    Button {
      width: fit-content;
      margin-top: 39px;
      border-radius: 7px;
    }
  }
}

.display-item {
  display: flex;
  margin-top: 12px;

  .ant-avatar {
    margin-right: 16px;
  }

  p {
    margin: 0;
  }

  &-title {
    font-size: 14px;
    font-weight: 500;
  }

  &-subtitle {
    font-size: 12px;
  }

  &-border {
    border-bottom: 1px solid $shade-gray;
    padding-bottom: 12px;
  }
}

.ant-radio-button-checked {
  border: 2px solid #1c8bff27 !important;
}

.ant-tabs-nav {
  background-color: white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary-yellow;
}

.ant-tabs-ink-bar {
  background-color: $primary-yellow;
}

.info-card {
  width: 363px;
  height: 262px;

  &-display {
    max-height: 145px;
    overflow-y: auto;
  }

  .ant-select {
    width: 100%;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: $shade-silver;
}

::-webkit-scrollbar-track {
  background: $shade-gray-5;
}

.image-uploader {
  height: 220px;

  .ant-upload-list {
    display: none;
  }

  .ant-upload {
    height: 160px;
  }
}

.image-uploader-buttons {
  width: 100%;
  display: flex;
  padding: 12px 20px;
  border-top: 1px solid $shade-gray;
  margin-bottom: 0;

  .ant-btn:first-of-type {
    margin-right: 20px;
    margin-left: auto;
  }
}

.collapse-subheading {
  font-size: 14px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  font-weight: bold;
  padding-bottom: 6px;
  border-bottom: 1px solid $landing-content;
}

.not-found-component {
  display: flex;
  flex-direction: column;
  width: 400px;

  margin: 20% auto 0;
  text-align: center;

  .ant-btn {
    margin: 0 auto;
    width: 150px;
  }
}

.project-validation-modal {
  .ant-modal-content {
    width: 468px;
    border-radius: 10px;
  }

  .ant-modal-body {
    border-bottom: none !important;
  }

  .anticon-info-circle {
    display: none;
  }

  .ant-modal-confirm-btns {
    width: 100%;
    display: flex;
    margin: 36px 0;

    .ant-btn-primary {
      margin: 0 auto;
      background-color: #fff !important;
      width: 98px;

      border-color: $primary-yellow !important;
      color: $primary-yellow !important;

      &:hover {
        background-color: rgba($primary-yellow, 0.1) !important;
        color: $primary-yellow !important;
      }

      span {
        display: none;
      }

      &::after {
        content: 'Close';
      }
    }
  }

  &-content {
    display: flex;

    .modal-hand {
      width: 102px;
      height: 102px;
      border-radius: 50%;
      background-color: #fadec2;
      display: flex;
      position: absolute;
      top: -51px;
      left: calc(50% - 51px);
    }

    img {
      width: 80px;
      height: 80px;
      margin: auto 0;
      margin-left: 8%;
    }
  }

  .pvm-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    &-title {
      text-align: center;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 23px;
    }

    p {
      width: 300px;
      margin: 0 auto;
    }

    &-issue {
      display: flex;
      margin-left: 55px;

      img {
        width: 44px;
        height: 44px;
        margin: 0;
      }

      &-details {
        margin-top: 11px;
        margin-left: 10px;
      }
    }
  }
}

.tab-header {
  font-size: 16px;
  line-height: 1.8em;
}

.loading-component {
  display: flex;
  height: 30vh;
}

.delete-gateway-p {
  width: 300px;
  margin: 0 auto 20px;
}

.delete-gateway-item {
  display: flex;

  &-x {
    color: red;
    font-weight: bold;
  }

  &-name {
    margin-left: 15px;
  }

  &-container {
    width: fit-content;
    margin: 0 auto;
  }
}

.ant-table-row {
  cursor: pointer;
}
.ant-btn {
  border-radius: 7px;
}
.ant-pagination-item {
  border-radius: 7px;
}
.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-next,
.ant-pagination-prev {
  border-radius: 7px;
}
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border-radius: 7px;
}
.ant-card {
  border-radius: 7px;
}
.ant-input {
  border-radius: 7px;
}
.ant-select {
  border-radius: 7px;
  .ant-select-selector {
    border-radius: 7px;
  }
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 7px;
}
.ant-select-dropdown {
  border-radius: 7px;
}
.ant-checkbox-inner {
  border-radius: 4px;
}
.ant-input-affix-wrapper {
  border-radius: 7px;
}
.currency-select-dropdown,
.aws-region-select-dropdown {
  width: 100%;
}

.aws-region-select-dropdown--option {
  .ant-select-item-option-content {
    display: flex;
    flex-wrap: row wrap;
    width: 100%;
  }
  .region-value {
    text-align: end;
    opacity: 0.75;
  }
  .region-name,
  .region-value {
    display: inline-block;
    width: 50%;
    min-width: 100px;
  }
}
