@import 'colors';

.team {
  &-add-member-button {
    width: fit-content;
    margin-left: auto;
  }

  .ant-layout-content {
    padding: 8px 28px 28px;
  }
}

.add-team-member-form {
  .ant-row:not(.add-team-member-form-buttons) {
    padding: 0 18px;
  }
  &-buttons {
    padding: 12px 20px;
    border-top: 1px solid $shade-gray;
    margin-bottom: 0;
    .ant-btn:first-of-type {
      margin-right: 20px;
      margin-left: auto;
    }

    .ant-form-item-control-input-content {
      display: flex;
    }
  }
}

.active-team {
  padding: 39px;

  &-add-member-button {
    // width: fit-content;
    // margin-left: auto;
    float: right;
    margin-bottom: 39px;
  }
}

.team-member-details {
  //padding: 39px;
  &-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-bottom: 19px;
    &-save,
    &-back {
      width: fit-content;
    }
    &-save {
      width: 100px;
    }
    &-back {
      margin-right: 20px;
      width: 100px;
    }
  }
  &-header {
    display: flex;
    background-color: white;
    height: 64px;
    padding: 16px 28px 0;
  }
  &-form {
    margin: 29px;
    background-color: #fff;
    padding: 27px;
    padding-bottom: 14px;
    .ant-form-item-label {
      text-align: left;
    }
  }
}

.team-member-name {
  font-weight: bold;
  color: $primary-blue;
}

.team-list {
  display: flex;
  flex-direction: column;

  .ant-space {
    margin-top: 15px;
    margin-left: auto;
  }
}
