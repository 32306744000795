@import 'colors';

.templates {
  padding: 39px;

  &-add-button {
    width: fit-content;
    margin-left: auto;
  }

  &-list-name {
    color: $shade-blue-3;
    font-weight: bold;
    cursor: pointer;
  }

  Table {
    p {
      margin: 0;
    }
  }

  &-add-card {
    cursor: pointer;
    border: 1px dashed $primary-blue;
    border-radius: 1px;
    width: 232px;
    height: 270px;
    display: flex;

    .ant-card-body {
      margin: auto;
    }

    &:hover {
      border: 1px solid $primary-blue;
      box-shadow: 0 2px 15px #00000041;
    }

    p {
      margin: 0;
      color: $primary-blue;
    }
  }

  &-list-card {
    height: 270px;
    border-radius: 1px;
    cursor: pointer;

    .ant-card-body {
      padding: 0;
    }

    &:hover {
      box-shadow: 0 2px 15px #00000041;
    }

    .list-card-banner {
      img {
        display: block;
        width: 230px;
        height: 166px;
      }
    }

    .list-card-buttons {
      display: flex;
      border-top: 1px solid #f0f0f0;

      .ant-btn {
        border: none;
        flex: 1;
        color: $primary-red;
        &:first-of-type {
          border-right: 1px solid #f0f0f0;
          color: #8c8c8c;
        }
      }
    }

    .list-card-space {
      padding: 11px 15px;

      a {
        color: $primary-blue;
        font-size: 16px;
        font-weight: 400;
      }

      p {
        margin: 0;
        text-overflow: ellipsis;
        max-width: 190px;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.view-template {
  &-header {
    display: flex;
    width: 100%;
    background-color: white !important;
    padding: 0 39px !important;

    h2 {
      margin: auto 0;
      line-height: 1.5em;
    }
  }

  .ant-tabs-nav-wrap {
    padding: 0 39px;
  }

  .ant-tabs-content-holder {
    padding: 0 39px;
  }

  &-buttons {
    margin-left: auto;

    .ant-btn {
      width: 92px;
    }
  }
}

.editor-collapse {
  border: none;

  .ant-collapse-header {
    background-color: white;
    color: black;
    font-size: 15px;
    font-weight: bold;
  }
}

.template-editor {
  background-color: white;
}

.template-commerce {
  &-subheading {
    font-size: 14px;
    color: #000000;
    text-decoration: none dashed rgb(0, 0, 0);
    font-weight: bold;
  }

  .cost-field {
    .ant-form-item-control-input-content {
      display: flex;
    }

    label {
      flex: 0 0 50%;
    }
  }
}

.template-layers {
  background-color: white !important;
  Button {
    margin: 20px auto 20px;
    width: fit-content;
  }

  .collapse-subheading {
    margin-bottom: 10px;
  }

  &-greenscreen {
    border: 1px dashed $shade-gray;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;

    img {
      height: 100px;
      width: 100%;
      object-fit: contain;
    }
  }
}

.image-layer {
  display: flex;
  flex-direction: row;

  &-active {
    background-color: rgba($shade-blue-2, 0.1);
    border: 2px solid $shade-blue-2;
  }

  &-burger {
    margin-left: auto;
    cursor: pointer;
  }

  .ant-btn {
    margin: auto 0;

    span {
      color: $primary-red !important;
    }
  }

  &-thumb {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

  span {
    font-size: 15px;
    color: black;
    height: fit-content;
    margin: auto 0;
  }
}

.selected-layer {
  border: 2px solid $shade-gray;
  width: 90%;
  margin: 0 auto;
  height: 87px;

  &-image {
    border-right: 2px solid $shade-gray;
    display: flex;

    img {
      width: 100%;
      margin: auto 0;
    }
  }

  .alignment-column {
    .ant-col {
      display: flex;
    }

    .ant-row {
      margin: 10px;
      flex-flow: row nowrap;
    }

    p {
      height: fit-content;
      font-size: 12px;
      color: gray;
      font-weight: 500;
      margin-bottom: 0;
      margin-top: auto;
    }

    .position-value {
      //width: 50%;
      border-bottom: 1px solid $shade-gray;
      margin-left: 5px;
      width: 100px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.layer-content-header {
  p {
    font-size: 15px;
    font-weight: bold;
    margin: 0;
  }

  .ant-col:first-of-type {
    border-right: 1px solid $sidenav;
    border-bottom: 1px solid $sidenav;
    padding: 14px 60px 16px 24px;
  }

  .ant-col:last-of-type {
    display: flex;
  }

  .ant-radio-group {
    margin: 11px auto;
  }
}

.layer-content {
  height: 70vh;

  .ant-col:first-of-type {
    display: flex;
    border-right: 1px solid $sidenav;
  }

  .ant-col:last-of-type {
    background-color: #f1f1f1;
  }

  Button.add-variation {
    margin: 20px auto 0;
  }

  &-col1 {
    flex-direction: column;
  }

  &-menu {
    .ant-menu-item-selected {
      color: black;
      background-color: #f1f1f1 !important;
    }
  }

  .variation-menu-item {
    display: flex;
    padding: 8px 16px 8px 34px;
    cursor: pointer;

    &:hover {
      color: #14679e;
    }

    img {
      margin: auto 0 auto 10px;
      height: 10px;
    }

    p {
      margin: 0;
    }

    &-active {
      background-color: #f1f1f1 !important;
    }

    &-edit {
      height: fit-content;
      margin: auto 0 auto auto;
    }

    input {
      background: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $shade-gray-2;
      padding: 0;
      width: 60%;

      outline: none;
    }
  }
}

.template-preview {
  height: 100%;
  padding: 7px 0;

  .ratio-tooltip {
    height: fit-content;
    margin: auto 10px;
  }

  &-dimensions {
    width: fit-content;
    margin: 0 auto;
    display: flex;

    p {
      margin: 0;
      font-style: italic;
    }
  }

  &-toolbar {
    width: fit-content;
    margin: auto auto 0;
  }
}

.add-template-form {
  .ant-row:not(.add-template-form-buttons) {
    padding: 0 18px;
  }

  &-greenscreen {
    font-weight: bold;
    font-size: 12px;
    margin-left: 17px;
    color: $primary-orange;
    height: 40px;
  }

  &-buttons {
    padding: 12px 20px;
    border-top: 1px solid $shade-gray;
    margin-bottom: 0;

    .ant-btn:first-of-type {
      margin-right: 12px;
      margin-left: auto;
    }

    .ant-form-item-control-input-content {
      display: flex;
    }
  }
}

.template-preview-toolbar {
  .ant-radio-button-wrapper {
    width: 52px;

    &:first-of-type {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    &:last-of-type {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  .ant-radio-button-wrapper-checked {
    background-color: #24a8a8 !important;
    border-color: #24a8a8 !important;

    &::before {
      background-color: #24a8a8 !important;
    }
  }

  &-zoom {
    border-radius: 8px;
    margin-left: 50px;

    .fullscreen-button {
      line-height: 5px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .ant-btn {
      border-color: #24a8a8;
    }

    &.ant-btn-group > .ant-btn:first-child:not(:last-child) {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    &.ant-btn-group > .ant-btn:last-child:not(:first-child) {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}

.skel-loader {
  background: rgba(190, 190, 190, 0.2);
  height: 270px;
  width: 270px;

  .ant-skeleton-button {
    height: 100%;
    width: 100%;
  }

  .ant-skeleton:first-of-type {
    height: 150px;
    width: 100%;
  }

  .ant-skeleton-paragraph {
    width: 70%;
  }
}

.variation-loader {
  height: 38px;
  padding: 8px 16px 8px 34px;

  .ant-skeleton-button,
  .ant-skeleton {
    width: 100%;
    height: 22px;
  }
}

.variation-confirmation {
  &-message {
    padding: 0 18px 22px;

    .variation-confirmation-delete {
      margin-top: 20px;
      color: $primary-red;

      h3 {
        margin: 0;
        color: $primary-red;
      }
    }
  }

  &-footer {
    padding: 12px 20px;
    border-top: 1px solid $shade-gray;
    margin-bottom: 0;
    display: flex;

    .ant-btn:first-of-type {
      margin-right: 20px;
      margin-left: auto;
    }
  }
}

.template-orientation-group {
  label {
    border-color: #1890ff;
  }

  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    color: #1890ff;
  }
}

.no-commerce {
  h4 {
    color: $primary-red;
  }

  a {
    font-size: 12px;
  }
}

.collections-filter {
  display: flex;
  margin: 20px 0;

  &-export {
    margin-left: auto;
    background-color: #3c824e;
    border-color: #3c824e !important;

    &:hover {
      background-color: $primary-green;
      border-color: $primary-green !important;
    }

    img {
      width: 20px;
    }
  }

  &-dates {
    margin-left: 10px;
  }
}

.snap-box {
  height: 60px;
  width: 60px;
  margin: 0 auto 20px;

  &-row {
    &:last-of-type {
      border-bottom: 2px solid #d9d9d9;
    }
  }

  &-col {
    cursor: pointer;

    width: 30px;
    border-top: 2px solid #d9d9d9;
    border-left: 2px solid #d9d9d9;

    &:last-of-type {
      border-right: 2px solid #d9d9d9;
    }

    &:hover {
      background-color: $shade-gray-6;
    }
  }
}

.template-card-placeholder {
  width: 230px;
  height: 166px;
  top: 0;
  position: absolute;
}
