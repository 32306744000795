@import 'colors';

.registration {
  margin: auto;

  .ant-card-body {
    padding: 0;
  }

  &-form {
    width: 500px;
    padding: 24px;
  }

  &-footer {
    display: flex;
    margin-top: 20px;
    border-top: 1px solid #f0f0f0;
    padding: 24px;

    &-login {
      margin: auto 0;
      display: flex;
    }

    .ant-btn {
      margin-left: auto;
    }

    p {
      margin: 0 10px 0 0;
    }
  }

  &-warning {
    font-size: 11px;
  }
}

.verify-email {
  width: 500px;
  border-radius: 5px;

  margin: auto;
  text-align: center;

  &-subtitle {
    margin-top: 40px;
  }

  .ant-btn {
    margin-top: 20px;
    width: 100px;
  }

  &-error {
    color: $primary-red;
    font-weight: bold;
    margin-top: 20px;
  }
}

.registration-packages {
  &.ant-layout {
    background-color: white !important;
    padding: 24px;
    margin-top: 1px;
  }
}

.packages-list {
  width: fit-content;
  margin: 20px auto 0;

  &-chip {
    height: 5px;
    width: 40px;
    border-radius: 2.5px;
    background-color: $primary-yellow;
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    gap: 50px 30px;
    justify-items: stretch;
    align-items: stretch;
  }
  &-content-outer {
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  .pl-message {
    width: 200px;
    margin-right: 20px;

    h1 {
      margin: 20px 0 30px;
      font-size: 30px;
      color: $primary-dark-blue;
      font-weight: bold;
      width: 185px;
    }

    h4 {
      color: $primary-dark-blue;
    }
  }

  .pl-package {
    border-radius: 15px;
    margin-right: 20px;
    height: 100%;
    box-shadow: 0 2px 15px #00000021;

    &-header {
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      background-color: $primary-blue;
      padding: 15px 8px 15px 15px;
      color: white;
      font-size: 16px;
      font-weight: 600;
    }
    &-body {
      padding: 30px;
    }

    &-option {
      color: $primary-dark-blue;
      font-size: 14px;

      padding: 5px 5px;
      margin: 0 5px;

      &.no-border {
        border-top: none !important;
      }
    }

    &.orange {
      .pl-package-header {
        background-color: $primary-yellow;
      }

      .pl-pricing-header {
        div {
          background-color: $primary-yellow;
        }
      }
    }
  }
}

.pl-pricing-header {
  display: flex;
  font-size: 10px;
  font-weight: bold;
  color: $primary-dark-blue;
  margin: 0 10px;
  text-align: center;

  div {
    background-color: $primary-blue;

    &:first-of-type {
      //width: fit-content;
      width: 55%;
      margin-right: 10px;
    }

    &:last-of-type {
      width: 45%;
      //width: fit-content;
    }
  }
}

.pl-package-body-inner {
  padding-bottom: 30px;
  border-bottom: 1px solid #e2e2e2;
  .pl-package-body-tile {
    font-size: 1.75rem;
    color: $primary-dark-blue;
    font-weight: bold;
  }
  .pl-package-body-description {
    font-size: 1.15rem;
    margin-bottom: 0;
  }
}
.pl-package-option-container {
  margin-top: 10px;
}
