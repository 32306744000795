@import 'colors';

.submit-notification-success {
  margin-right: 27px;
  // background-color: $primary-green;
}
.submit-notification-error {
  margin-right: 27px;
  // background-color: $primary-red;
}
