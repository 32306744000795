@import 'colors.scss';

// primary[blue] styling
.ant-btn:not(.ant-btn-text):not(.ant-btn-dangerous) {
  border-color: $primary-blue;
}

.ant-btn-primary {
  background: $primary-blue;
}

// yellow styling for border button
.yellow-ant-btn {
  border-color: $primary-yellow !important;
  color: $primary-yellow;

  &:hover {
    background-color: rgba($primary-yellow, 0.1);
    color: $primary-yellow;
  }
}

.ant-btn-dangerous {
  border-color: #ff7875;
  border-radius: 4px;
}
