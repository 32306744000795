.sales {
  &-header {
    &.ant-layout-header {
      height: 64px !important;
    }
    div {
      padding: 25px 28px 0;
      display: flex;
      height: 100%;
      width: 100%;
      background-color: white;
      h2 {
        margin: 0;
        line-height: normal;
      }
    }
  }
  .sales-tabs {
    .ant-tabs-tab-btn {
      font-size: 18px;
    }
    .ant-tabs-tab:not(:first-of-type) {
      margin-left: 50px;
    }
    .ant-tabs-nav-wrap {
      padding: 0 28px;
    }
    .ant-tabs-content-holder {
      margin: 15px 28px;
    }
  }
}
.sales-summary-reports {
  .sales-summary-cards {
    display: flex;
    gap: 28px;
  }
  .ant-card {
    flex: 1;
  }
  .ant-card:last-child {
    flex-grow: 2;
    .ant-card-body {
      .first-row,
      .second-row,
      .third-row {
        display: flex;
        align-items: center;
        .select {
          display: flex;
          flex: 1;
          gap: 27px;
          p {
            width: 132px;
          }
        }
      }
    }
  }
}
.transactions-table {
  background-color: white;
  padding: 20px;
  margin-top: 39px;

  &-header {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;

    h3 {
      width: fit-content;
    }

    .ant-radio-group {
      margin-left: auto;
      margin-right: 18px;
    }
  }
}
.sales-add-payment {
  .yellow-ant-btn {
    margin-right: 20px;
  }
  h3 {
    margin-bottom: 20px;
    margin-right: auto;
  }
  &-button {
    width: fit-content;
  }
}
.add-payment-method-form,
.add-payment-details-form {
  .ant-form-item {
    padding: 0 18px;
  }

  &-buttons {
    border-top: 1px solid $shade-gray;
    width: 100%;
    display: flex;
    padding: 12px 20px !important;
    margin-bottom: 0;

    .cancel-button {
      margin-right: 20px;
      margin-left: auto;
    }

    .ant-form-item-control-input-content {
      display: flex;
    }
  }
  label.paygate,
  label.paypal {
    background-color: #e4e8ea;
    width: 100%;
    padding: 0 25px;
    img {
      width: 65%;
    }
  }
  label.paypal {
    margin: 20px 0 0;
  }
}

.sales-currency {
  display: flex;
  flex-direction: column;

  .ant-btn {
    margin-left: auto;
    margin-bottom: 17px;
    width: 100px;
  }

  &-title {
    display: flex;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.currency-item {
  display: flex;

  img {
    width: 40px;
    height: 40px;

    margin: auto 0;
  }

  input {
    width: 117px;
    height: 30px;

    margin: auto 73px auto 0;
  }

  &-code {
    font-weight: 500;
    font-size: 16px;
    margin: auto 10px;
  }

  &-name {
    font-size: 12px;
    margin: auto 0;
  }

  &-title {
    display: flex;
    width: 211px;
    margin-left: 20px;
  }

  .ant-switch,
  .ant-radio-wrapper {
    margin: auto 0;
  }
}

.add-payment-method {
  flex-direction: column;
  display: flex;
  padding: 0 18px;

  .ant-radio-wrapper {
    margin-bottom: 20px;
  }

  img {
    height: 50px;
    margin-left: 20px;
  }

  &-buttons {
    width: 100%;
    display: flex;
    padding: 12px 20px;
    border-top: 1px solid $shade-gray;

    .ant-btn:first-of-type {
      margin: 0 20px 0 auto;
    }
  }
}

.report-filter-dates {
  width: 100%;
  .ant-picker {
    width: 100%;
  }
}

.sales-currency-list-header {
  display: flex;

  p {
    margin-bottom: 0;
  }

  .list-header {
    &-allow {
      width: 44px;
    }

    &-name {
      width: 211px;
      margin-left: 20px;
    }

    &-single {
      width: 117px;
      margin-right: 73px;
    }

    &-all {
      width: 117px;
      margin-right: 73px;
    }

    &-default {
    }
  }
}

.amount-zar {
  font-size: 40px;
  font-weight: bold;
  color: $primary-yellow;
  margin-bottom: 0px;

  &-4 {
    font-size: 35px;
  }

  &-5 {
    font-size: 30px;
  }

  &-6 {
    font-size: 25px;
  }
}

.sales-summary-footer {
  margin-top: 10px;
}
