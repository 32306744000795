@import 'colors';

.projects {
  &-add-button {
    width: fit-content;
    margin-left: auto;
  }

  .ant-layout-content {
    padding: 28px;
  }
}

.add-project-form {
  .ant-row:not(.add-project-form-buttons) {
    padding: 0 18px;
  }

  &-buttons {
    padding: 12px 20px;
    border-top: 1px solid $shade-gray;
    margin-bottom: 0;

    .ant-btn:first-of-type {
      margin-right: 20px;
      margin-left: auto;
    }

    .ant-form-item-control-input-content {
      display: flex;
    }
  }
}

.project-photo-img {
  width: 15vw;
  height: 15vh;
  object-fit: contain;
}

.view-project {
  &-header {
    &.ant-layout-header {
      height: 64px !important;
    }

    div {
      padding: 16px 28px 0;
      display: flex;
      height: 100%;
      width: 100%;
      background-color: white;

      h2 {
        margin: 0;
        line-height: normal;
      }
    }
  }

  .activate-project {
    background-color: $shade-green;
    border: none;
    margin-left: auto;

    &:hover {
      background-color: $secondary-blue-hover;
    }

    &:active {
      background-color: $secondary-blue-active;
    }
  }

  &-row {
    margin-top: 23px;

    .ant-card {
      width: 24vw;
      min-width: 361px;
      margin-right: 36px;
      margin-bottom: 30px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &-tabs {
    .ant-tabs-tab-btn {
      font-size: 18px;
    }

    .ant-tabs-tab:not(:first-of-type) {
      margin-left: 50px;
    }

    .ant-tabs-nav-wrap {
      padding: 0 28px;
    }

    .ant-tabs-content-holder {
      padding: 0 28px 25px;
    }
  }
}

.live-projects {
  &-name {
    color: $primary-blue;
  }

  .little-circle {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin-right: 8px;

    &.active {
      background-color: $primary-green;
    }

    &.archived {
      background-color: $primary-red;
    }

    &.inactive {
      background-color: $primary-yellow;
    }

    &.deactivated {
      background-color: $primary-red;
    }
  }
}

.photos-taken-card {
  &-gray {
    color: $shade-gray-2;
    font-size: 14px;
    margin-bottom: 0;
  }

  &-amount {
    color: black;
    font-size: 45px;
    margin-bottom: 60px;
    line-height: 45px;
  }
}

.project-info-item {
  display: flex;

  &-title {
    flex: 1;
    color: $shade-gray-2;
    font-size: 14px;
  }

  &-info {
    flex: 2;
  }

  .little-circle {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin: auto 8px auto 0;

    &.active {
      background-color: $primary-green;
    }

    &.archived {
      background-color: $primary-red;
    }

    &.inactive {
      background-color: $primary-yellow;
    }

    &.deactivated {
      background-color: $primary-red;
    }
  }
}

.project-templates {
  .ant-layout-header {
    display: flex;

    h2 {
      line-height: 1.5em;
    }

    .ant-btn:first-of-type {
      margin-left: auto;
    }
  }

  .empty-list {
    margin-top: 0;
  }
}

.project-templates-table {
  &-name {
    font-weight: bold;
    color: $primary-blue;
    cursor: pointer;
  }
}

.project-no-templates {
  h3 {
    margin-bottom: 27px;
  }

  &-buttons {
    display: flex;
  }

  .no-template-option {
    border: 1px solid $shade-gray-2;
    width: 50%;
    padding: 24px 18px;
    display: flex;
    cursor: pointer;
    border-radius: 7px;

    &:hover {
      box-shadow: 0 2px 15px #00000041;
    }

    &:first-of-type {
      margin-right: 38px;
    }

    img {
      width: 58px;
      height: 58px;
      margin-right: 21px;
    }

    &-text {
      margin: 0;
      font-size: 18px;
      color: $primary-dark;
      font-weight: 500;
    }

    &-subtext {
      margin: 0;
      font-size: 14px;
      color: $shade-gray-3;
    }
  }
}

.project-photos {
  background-color: #fff;
  padding: 24px;

  .photos-taken-select {
    align-items: center;
    margin-bottom: 15px;
    margin-right: 20px;

    .ant-select,
    .ant-input {
      width: 180px;
    }

    p {
      &:first-child {
        margin-right: 15px;
      }
      &:last-child {
        margin-left: 15px;
      }
    }
  }
}

.project-settings {
  .ant-layout-header {
    display: flex;

    h2 {
      line-height: 1.5em;
    }

    .ant-btn {
      margin-left: auto;
    }
  }

  &-form {
    background-color: white;
    padding: 30px;

    .project-description {
      width: 40%;
    }

    .project-name {
      width: 40%;
    }
  }
}

.project-website {
  //height: 100%;

  .ant-layout-header {
    display: flex;

    h2 {
      line-height: 1.5em;
    }

    .ant-btn:first-of-type {
      margin-left: auto;
    }
  }

  &-content {
    background-color: white;
    //
    //.ant-row {
    //  height: 100%;
    //}
  }
}

.project-popups-table {
  .ant-pagination {
    padding-right: 10px;
  }

  .ant-table-thead {
    th {
      background-color: white;
    }
  }

  &-title {
    color: $primary-blue;
    font-weight: bold;
  }
}

.select-template {
  width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  img {
    width: 100px;
    margin: 0 auto;
  }

  h3 {
    margin: 20px auto 0;
  }

  p {
    margin: 0 auto;
    width: fit-content;
  }

  &-list {
    margin: 20px auto 0;

    &-item {
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 7px;
      border: 1px solid $primary-yellow;
      margin-bottom: 10px;

      h3 {
        margin: 0 auto;
        width: fit-content;
        color: $primary-yellow;
      }

      &:hover {
        background-color: $shade-gray-6;
      }

      &:active {
        opacity: 60%;
      }

      .subtitle {
        font-size: 12px;
        width: fit-content;
        margin: 0 auto;
      }
    }
  }
}

.project-member-name {
  font-weight: bold;
  color: $primary-blue;
  cursor: pointer;
}

.project-members {
  &-title {
    font-weight: 500;
    font-size: 16px;
    margin-right: auto;
  }
}

.delete-confirmation {
  .ant-modal-content {
    width: 468px;
    border-radius: 10px;
  }

  .ant-modal-close-x,
  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    border-bottom: none !important;
  }

  .ant-modal-confirm-btns {
    display: none;
  }

  .anticon-info-circle {
    display: none;
  }

  &-content {
    .modal-hand {
      width: 102px;
      height: 102px;
      border-radius: 50%;
      background-color: #fadec2;
      display: flex;
      position: absolute;
      top: -51px;
      left: calc(50% - 51px);
    }

    img {
      width: 80px;
      height: 80px;
      margin: auto 0;
      margin-left: 8%;
    }
  }

  &-buttons {
    display: flex;
    margin: 20px auto 30px;

    .ant-btn:first-of-type {
      margin-right: 20px;
    }
  }
}

.project-template-preview {
  width: 170px;
  height: 100px;
  object-fit: contain;

  .template-placeholder {
    position: absolute;
    top: 0;
  }
}

.project-photos-rangepicker {
  margin-left: 20px;
}
