.project-popup {
  .ant-layout-header {
    display: flex;

    h2 {
      line-height: 1.5em;
    }
  }

  .ant-layout-content:not(.popup-view) {
    background-color: white;
  }

  &-collection-button {
    background-color: $shade-gray-4;
    border-color: $shade-gray-4 !important;
    margin-right: 10px;

    &:hover {
      border-color: $primary-blue !important;
    }
  }
}

.create-popup {
  padding: 20px 50px;
  height: 368px;

  .ant-steps {
    width: 35%;
    margin: 0 auto;

    &-item {
      padding-left: 0 !important;
    }

    &-item-title::after {
      left: 0;
    }

    &-item-icon {
      margin-right: 0;
    }
  }

  &-buttons {
    width: fit-content;
    margin: 40px auto 0;
  }

  &-previous {
    border-color: $shade-gray-4 !important;
    color: $shade-gray-4;
    margin-right: 12px;

    &:hover {
      border-color: $primary-blue !important;
    }
  }

  &-step {
    h3 {
      margin-top: 50px;
    }
  }
}

.data-collection-form {
  margin: 18px 0 54px;

  .ant-row {
    margin-bottom: 10px;
  }
}

.popup-settings-form {
  margin-top: 20px;

  &-mode {
    .ant-form-item-label {
      overflow: visible;
    }
  }

  .ant-select {
    width: 215px;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }

  &-collection {
    display: flex;
    flex-wrap: wrap;

    div {
      height: fit-content;
      margin: auto 0;
    }

    input {
      margin: 0 10px;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.popup-preview {
  height: 650px;
  .ant-layout-header {
    background-color: white !important;
  }

  &-collecting {
    height: 16px;
    line-height: 16px;
    margin: auto;
    font-weight: bold;
    color: $primary-red;
  }
}

.popup-view {
  background-color: $shade-gray;
  display: flex;

  &-preview {
    width: 300px;
    height: 410px;
    background-color: white;
    margin: auto;
    border-radius: 7px;

    display: flex;
    flex-direction: column;
  }

  &-preview-header {
    flex: 0 1 100px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    padding: 17px;
    p {
      margin: 0;
    }
    h2 {
      margin: 0;
    }
  }

  &-preview-buttons {
    display: flex;

    .ant-btn:first-of-type {
      margin-right: auto;
    }
  }

  &-preview-form {
    flex: 1;
    padding: 17px 17px 0;

    input {
      height: 40px;
      margin-bottom: 17px;
    }

    .ant-btn {
      border-radius: 7px;
      height: 38px;

      &:hover {
        opacity: 70%;
      }
    }
  }

  &-preview-footer {
    flex: 0 1 67px;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;

    padding: 14px;
  }
}
