.project-website-collapse {
  border: none;

  .ant-collapse-header {
    background-color: white;
    color: black;
    font-size: 15px;
    font-weight: bold;
  }
}

.website-header {
  background-color: white !important;

  .ant-upload-list {
    display: none;
  }

  &-url {
    margin-top: 17px;
    margin-bottom: 20px;
  }

  &-subheading {
    font-size: 14px;
    color: #000000;
    //text-decoration: none solid rgb(0, 0, 0);
    font-weight: bold;
    padding-bottom: 6px;
    text-decoration: underline;
  }

  &-uploader {
    margin-top: 17px;
    width: 100%;
    display: flex;
    flex-direction: column;

    font-family: Arial;
    color: black;
    font-size: 11px;
    text-align: center;
    line-height: 14px;

    border: 0.5px dashed $shade-gray-2;
    background-color: #fafafa;
    border-radius: 6px;
    padding: 20px;
    cursor: pointer;
  }

  .ant-btn-dangerous {
    margin-left: auto;
  }
}

.website-sharing-form {
  .ant-select {
    width: 150px;
  }
}

.website-theme {
  &-option {
    display: flex;
    width: 265px;

    .ant-tag {
      width: 80px;
      height: 29px;
      border-radius: 7px;
      border: 1px $shade-gray solid;
      margin: 0;

      &:first-of-type {
        margin-right: 11px;
        margin-left: auto;
      }
    }
  }

  &-option-label {
    flex-basis: 30%;
  }

  &-selected {
    display: flex;
    height: 29px;
    margin-top: 20px;

    p {
      height: fit-content;
      margin: auto 0;
    }

    .ant-tag {
      width: 194px;
      min-width: 194px;
      border-radius: 7px;
      border: 1px $shade-gray solid;
      margin: 0 0 0 auto;
      text-align: center;
      padding-top: 2px;
      cursor: pointer;
    }
  }
}

.theme-color {
  display: flex;
  margin-top: 20px;

  &-picker {
    height: 150px !important;
  }

  &-input {
    width: 100px;
    margin-top: 10px;
    height: fit-content;
  }
}

.add-custom-theme {
  margin: 0 auto;
  color: $shade-gray-4;
  border-color: $shade-gray-4 !important;
  border-radius: 4px;

  &:hover {
    border-color: $primary-blue !important;
  }
}

.website-preview {
  height: 95%;
  .ant-layout-header {
    background-color: white !important;
    display: flex;
  }

  .ant-layout-content {
    background-color: $shade-gray-5;
    height: 535px;
    display: flex;
    flex-direction: column;
  }

  &-device {
    margin: auto;
    .ant-radio-button-wrapper {
      &:last-child {
        border-radius: 0 7px 7px 0;
      }
      &:first-child {
        border-radius: 7px 0 0 7px;
      }
    }
  }
}

.website-wrapper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  margin: auto;

  height: 100%;

  &-mobile {
    width: 385px;
  }

  &-tablet {
    width: 432px;
    margin: 0 auto;
  }

  &-desktop {
    height: 506px;
    width: 655px;
  }

  ::-webkit-scrollbar {
    width: 5px !important;
  }
}

.website-outline {
  position: fixed;

  &-mobile {
    //left: 35.5%;
    //left: 0;
    //top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    height: 52%;
  }

  &-tablet {
    left: 32.3%;
    height: 52%;
    top: 217px;
    width: 373px;
  }

  &-desktop {
    left: 25.4%;
    height: 52%;
    top: 229px;
    width: 637px;
  }
}

.website-view-photo-socials-icon {
  height: 25px;
  width: 25px;
}

.website-view {
  background-color: white;
  margin: auto;
  height: 100%;
  display: flex;
  overflow-y: auto;

  &-inner {
    display: flex;
    flex-direction: column;
  }

  &-mobile {
    width: 227px;
    height: 398px;

    .website-view-photo-frame {
      height: 178px;
    }
  }

  &-tablet {
    width: 336px;
    height: 432px;

    .website-view-photo-frame {
      width: 285px;
      height: 225px;
      margin: 0 auto;
    }
  }

  &-desktop {
    width: 596px;
    height: 324px;

    margin: 24px auto 0;

    .website-view-photo-frame {
      width: 285px;
      height: 225px;
      margin: 0 auto;
    }

    .website-view-footer,
    .website-view-header {
      text-align: center;
      img {
        width: 285px;
      }
    }
  }

  &-header {
    width: 100%;
    object-fit: contain;

    img {
      width: 100%;
    }
  }

  &-footer {
    width: 100%;
    object-fit: contain;

    img {
      width: 100%;
    }
  }

  &-photo-block {
    margin: 5px 8px;

    img:not(.website-view-photo-socials-icon) {
      width: 100%;
    }
  }

  &-photo-frame {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 5px;
  }

  &-photo-socials {
    // height: 60px;
    width: fit-content;
    margin: 0 auto;
  }
}

.wp-thumbnail {
  // &-mobile {
  //   height: 250px;
  // }

  &-tablet {
    height: 193px;
  }

  &-desktop {
    height: 193px;
  }
}
