:root {
  /* Main guideline colours */
  --primary-blue: #1c8bff;
  --primary-dark-blue: #0a387c;
  --primary-yellow: #fbba00;
  --primary-orange: #f39200;
  --primary-green: #52c41a;
  --primary-red: #ff4d4f;
  --primary-purple: #457fc1;
  --primary-blue-light: #e6f6ff;
  --primary-yellow-light: #fff8e5;
  --primary-orange-light: #fef4e6;
  --primary-purple-light: #ecf2f9;

  /* Secondary colours from wireframes */
  --sidenav: #dadada;
  --landing-content: #dadada;
  --shade-silver: #cccccc;
  --shade-blue-1: #f8f9fa;
  --shade-blue-2: #336ef5;
  --shade-blue-3: #3498db;
  --shade-blue-4: #1779ba;
  --shade-green: #70d780;
  --shade-gray: #d9d9d9;
  --shade-gray-2: #8c8c8c;
  --shade-gray-3: #6e6e6e;
  --shade-gray-4: #6c757d;
  --shade-gray-5: #f1f1f1;
  --shade-gray-6: #f1f1f1;
  --shade-gray-7: #545b62;

  /* Additional colours */
  --secondary-blue: #007bff;
  --secondary-blue-hover: #0069d9;
  --secondary-blue-active: #0062cc;
  --button-gray-hover: #545b62;
  --button-gray: #6c757d;

  --primary-dark: #1d1d1d;
}

/* Assign SCSS variables to the CSS variables */
$primary-blue: var(--primary-blue);
$primary-dark-blue: var(--primary-dark-blue);
$primary-yellow: var(--primary-yellow);
$primary-orange: var(--primary-orange);
$primary-green: var(--primary-green);
$primary-red: var(--primary-red);
$primary-purple: var(--primary-purple);
$primary-blue-light: var(--primary-blue-light);
$primary-yellow-light: var(--primary-yellow-light);
$primary-orange-light: var(--primary-orange-light);
$primary-purple-light: var(--primary-purple-light);

$sidenav: var(--sidenav);
$landing-content: var(--landing-content);
$shade-silver: var(--shade-silver);
$shade-blue-1: var(--shade-blue-1);
$shade-blue-2: var(--shade-blue-2);
$shade-blue-3: var(--shade-blue-3);
$shade-blue-4: var(--shade-blue-4);
$shade-green: var(--shade-green);
$shade-gray: var(--shade-gray);
$shade-gray-2: var(--shade-gray-2);
$shade-gray-3: var(--shade-gray-3);
$shade-gray-4: var(--shade-gray-4);
$shade-gray-5: var(--shade-gray-5);
$shade-gray-6: var(--shade-gray-6);
$shade-gray-7: var(--shade-gray-7);

$secondary-blue: var(--secondary-blue);
$secondary-blue-hover: var(--secondary-blue-hover);
$secondary-blue-active: var(--secondary-blue-active);
$button-gray-hover: var(--button-gray-hover);
$button-gray: var(--button-gray);

$primary-dark: var(--primary-dark);
