@import 'colors';

.pf-container {
  background-color: #fff;
  padding: 24px;
}

.pf-filter-container {
  display: flex;
  width: 100%;
}

.pf-option {
  align-items: center;
  margin-bottom: 15px;
  margin-right: 20px;

  &__label {
    color: $shade-gray-2;
    font-size: 14px;
    margin-bottom: 0;
  }
}

.pf-rangepicker {
  margin-left: 20px;
}

.pf-option .ant-select,
.pf-option .ant-input {
  width: 180px;
}
.pf-input-button-combo-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 150px !important;
}
.pf-input-button-combo-button,
.pf-input-button-combo-button > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
