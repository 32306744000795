@import 'colors.scss';

.login-card {
  margin: auto;
  //width: 30%;
  width: 500px;

  &-buttons {
    margin-top: 20px;

    .ant-form-item-control-input-content {
      display: flex;
    }
  }
}

.forgot-password {
  height: 100vh;

  &-card {
    width: 40%;
    margin: auto;

    .ant-card-body {
      width: fit-content;
      margin: 0 auto;
    }

    p {
      width: 350px;
    }

    Form {
      margin-top: 20px;

      Button {
        margin-top: 10px;
      }
    }
  }
}
